import PageDetail from '../components/page/detail';
import { START_PAGE_UID } from '../lib/config';
import { getCustomTypeStaticProps, pageQuery } from '../lib/custom-type';

const Home = (props) => <PageDetail {...props} />;

export default Home;

export async function getStaticProps(props) {
  return await getCustomTypeStaticProps({
    gqlQuery: pageQuery,
    variables: { uid: START_PAGE_UID, lang: props.locale },
    ...props,
  });
}
